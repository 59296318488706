html {
  font-size: var(--pst-font-size-base);
  scroll-padding-top: calc(var(--pst-header-height) + 12px);
}

body {
  padding-top: var(--pst-header-height);

  background-color: white;
  font-family: var(--pst-font-family-base);
  font-weight: 400;
  line-height: 1.65;
  color: rgba(var(--pst-color-text-base), 1);
}

p {
  margin-bottom: 1.15rem;
  font-size: 1em;
  color: rgba(var(--pst-color-paragraph), 1);

  /* section header in docstring pages */
  &.rubric {
    border-bottom: 1px solid rgb(201, 201, 201);
  }
}

a {
  color: rgba(var(--pst-color-link), 1);
  text-decoration: none;

  &:hover {
    color: rgba(var(--pst-color-link-hover), 1);
    text-decoration: underline;
  }

  &.headerlink {
    color: rgba(var(--pst-color-headerlink), 1);
    font-size: 0.8em;
    padding: 0 4px 0 4px;
    text-decoration: none;

    &:hover {
      background-color: rgba(var(--pst-color-headerlink), 1);
      color: rgba(var(--pst-color-headerlink-hover), 1);
    }
  }
}

.heading-style {
  margin: 2.75rem 0 1.05rem;
  font-family: var(--pst-font-family-heading);
  font-weight: 400;
  line-height: 1.15;
}

h1 {
  @extend .heading-style;
  margin-top: 0;
  font-size: var(--pst-font-size-h1);
  color: rgba(var(--pst-color-h1), 1);
}

h2 {
  @extend .heading-style;
  font-size: var(--pst-font-size-h2);
  color: rgba(var(--pst-color-h2), 1);
}

h3 {
  @extend .heading-style;
  font-size: var(--pst-font-size-h3);
  color: rgba(var(--pst-color-h3), 1);
}

h4 {
  @extend .heading-style;
  font-size: var(--pst-font-size-h4);
  color: rgba(var(--pst-color-h4), 1);
}

h5 {
  @extend .heading-style;
  font-size: var(--pst-font-size-h5);
  color: rgba(var(--pst-color-h5), 1);
}

h6 {
  @extend .heading-style;
  font-size: var(--pst-font-size-h6);
  color: rgba(var(--pst-color-h6), 1);
}

small,
.text_small {
  font-size: var(--pst-font-size-milli);
}

hr {
  border: 0;
  border-top: 1px solid #e5e5e5;
}

pre,
code,
kbd,
samp {
  font-family: var(--pst-font-family-monospace);
}

code {
  color: rgba(var(--pst-color-inline-code), 1);
}

pre {
  margin: 1.5em 0 1.5em 0;
  padding: 10px;
  background-color: rgba(var(--pst-color-preformatted-background), 1);
  color: rgba(var(--pst-color-preformatted-text), 1);
  line-height: 1.2em;
  border: 1px solid rgb(201, 201, 201);
  border-radius: 0.2rem;
  box-shadow: 1px 1px 1px #d8d8d8;
}

// Override bootstrap by restoring the basic theme default.
dd {
  margin-top: 3px;
  margin-bottom: 10px;
  margin-left: 30px;
}

// Prevent field lists from stretching too much on narrow screens
// ref: https://css-tricks.com/preventing-a-grid-blowout/
dl.field-list {
  display: grid;
  grid-template-columns: fit-content(30%) minmax(0, 1fr);
}
