:root {
  --napari-primary-bue: #80d1ff;
  --napari-deep-blue: #009bf2;
  --napari-light-blue: #d2efff;
  --napari-dark-gray: #686868;
  --napari-gray: #f7f7f7;

  --pst-font-family-base: Barlow, var(--pst-font-family-base-system);
  --pst-font-family-heading: Barlow, var(--pst-font-family-base-system);
  --pst-font-family-monospace: 'JetBrains Mono',
    var(--pst-font-family-monospace-system);

  --pst-color-headerlink: var(--napari-dark-gray);
  --pst-color-headerlink-hover: var(--napari-deep-blue);
  --pst-color-inline-code: 0, 155, 242;
}
