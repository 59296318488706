@import '@/scss/utils';

.section-nav {
  @apply tw-border-none;
}

.toc-entry a,
.section-nav ul {
  @apply tw-pl-0;
}

.toc-entry a {
  margin-bottom: to-rem(4px);

  a {
    line-height: 1.5 !important;
  }
}

@for $i from 2 through 5 {
  .toc-h#{$i} a {
    @apply tw-text-sm tw-leading-6 tw-no-underline;

    color: #000 !important;
    padding-left: ($i - 2) * 16px + 25px;

    &:hover {
      border-left: 3px solid theme('colors.napari-primary');
    }
  }
}

#bd-toc-nav {
  border-left: 1px solid black;
}

.toc-entry > .nav-link {
  border-left: 3px solid transparent;

  &:hover {
    border-left: 3px solid theme('colors.napari-primary');
  }

  &.active {
    border-left: 3px solid #000;
  }
}

.onthispage {
  @apply tw-p-0 tw-border-none tw-uppercase tw-text-sm tw-font-semibold;

  color: #000 !important;
  margin: 0 !important;
}

.bd-toc .nav .nav.visible {
  display: block;
}

.editthispage {
  @apply tw-border-none tw-p-0 tw-text-black;

  a {
    @apply tw-no-underline tw-text-sm tw-font-semibold;
    @apply tw-flex tw-items-center tw-space-x-2;
  }
}
