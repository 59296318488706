@import '@/scss/utils';

* {
  box-sizing: border-box;
}

html,
body {
  @apply tw-w-screen tw-h-screen tw-p-0 tw-m-0;
}

main[role='main'] {
  padding: 0 to-rem(25px) 0 !important;

  @media (min-width: 720px) {
    padding: to-rem(128px) to-rem(50px) 0 !important;
  }
}

html {
  font-size: 100%;
}

.napari-page {
  @apply tw-grid tw-justify-center tw-gap-6 tw-pt-32;

  min-height: 95vh;
  grid-template-columns: 1fr;

  @screen screen-875 {
    grid-template-columns: 775px;
  }

  @screen screen-1150 {
    @apply tw-gap-12;
    grid-template-columns: 225px 775px;
  }

  @screen screen-1425 {
    grid-template-columns: 225px 775px 225px;
  }

  .bd-sidebar,
  .bd-toc,
  .bd-content {
    max-width: unset;
  }

  .bd-sidebar {
    @apply tw-border-none tw-px-6 tw-static tw-h-max;

    padding-top: 0 !important;

    @media (min-width: 1150px) {
      @apply tw-block tw-pt-12 tw-px-2;
    }
  }

  .bd-content {
    @apply tw-w-screen;
    padding: 0 to-rem(25px) !important;

    @screen screen-875 {
      @apply tw-w-full;
    }

    section {
      max-width: 100%;
      overflow-x: auto;
    }

    img {
      @apply tw-w-full tw-max-w-max;
    }
  }

  .bd-toc {
    @apply tw-hidden tw-col-start-3 tw-space-y-6 tw-top-20;
    @apply tw-pb-12;

    @media (min-width: 1425px) {
      @apply tw-block;
    }
  }
}
