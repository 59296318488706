@import '@/scss/utils';

a {
  @apply tw-underline;

  &,
  &:hover {
    @apply tw-text-black;
  }

  &.headerlink {
    color: var(--pst-color-headerlink);

    &:hover {
      @apply tw-bg-transparent;
      color: var(--pst-color-headerlink-hover);
    }
  }
}

code {
  @apply tw-bg-napari-hover-gray tw-text-black tw-leading-6 tw-p-1;
}

span.guilabel {
  @apply tw-font-barlow tw-font-semibold;
  @apply tw-border-none tw-rounded-none;
  @apply tw-bg-napari-light tw-py-1;

  font-size: to-rem(17px);
  line-height: to-rem(25.5px);
}

aside.sidebar {
  @apply tw-border-b tw-border-napari-primary;
  @apply tw-bg-napari-light;

  padding: to-rem(8px) to-rem(12px);

  @screen screen-495 {
    padding: to-rem(20px) to-rem(25px);
  }

  p {
    @apply tw-font-medium;

    font-size: to-rem(9px);

    @screen screen-495 {
      font-size: to-rem(14px);
    }

    &.sidebar-title {
      @apply tw-font-bold;

      font-size: to-rem(11px) !important;
      line-height: 175%;

      @screen screen-495 {
        font-size: to-rem(17px) !important;
        line-height: 141%;
      }
    }
  }
}

@for $i from 1 through 6 {
  h#{$i} {
    @apply tw-font-bold;

    color: #000 !important;
  }
}

h1 {
  font-size: to-rem(23px);
  line-height: 130%;
  margin-bottom: to-rem(43px);

  @screen screen-495 {
    font-size: to-rem(35px);
    line-height: 125%;
  }
}

h2 {
  font-size: to-rem(16px);
  line-height: 125%;

  @screen screen-495 {
    font-size: to-rem(24px);
    line-height: 150%;
  }
}

h3,
p.rubric {
  font-weight: 700 !important;
  font-size: to-rem(13px) !important;
  line-height: 125%;

  @screen screen-495 {
    font-size: to-rem(20px) !important;
    line-height: 135%;
  }
}

p.rubric {
  border-top: 1px solid black;
  border-bottom: none;
  padding-top: to-rem(15px);
  font-size: to-rem(9px);

  @screen screen-495 {
    font-size: to-rem(14px);
  }
}

h4,
.sidebar-title,
.sidebar .rubric {
  font-size: to-rem(11px) !important;
  line-height: 175%;

  @screen screen-495 {
    font-size: to-rem(17px) !important;
    line-height: 141%;
  }
}

h5 {
  font-size: to-rem(14px);
  line-height: 150%;
}

h6 {
  font-size: to-rem(11px);
  line-height: to-rem(16.5px);
}

p,
.line-block .line {
  @apply tw-leading-normal;

  font-size: to-rem(11px);
  color: #000 !important;

  @screen screen-495 {
    font-size: to-rem(17px);
  }
}

code.xref,
a code {
  @apply tw-font-normal;
}

p.centered {
  @apply tw-text-center;
}

.prev-next-area {
  @apply tw-flex tw-flex-auto tw-justify-between;

  margin-bottom: to-rem(75px);

  a {
    padding: to-rem(25px);
  }
}

.left-prev,
.right-next {
  .prev-next-title,
  .prev-next-subtitle {
    @apply tw-m-0;

    line-height: to-rem(25.5px);
  }

  .prev-next-subtitle {
    @apply tw-font-bold tw-m-0;

    font-size: to-rem(11px);
    text-transform: uppercase !important;
  }

  .prev-next-title {
    font-size: to-rem(17px);
  }

  &:hover {
    @apply tw-bg-napari-hover-gray;

    .prev-next-title {
      text-decoration: none !important;
    }
  }

  svg {
    @apply tw-min-w-min;
  }
}

.left-prev {
  transform: translateX(-45px);

  .prev-next-info {
    margin: 0 !important;
    margin-left: to-rem(25px) !important;
  }
}

.right-next {
  transform: translateX(45px);

  .prev-next-info {
    margin: 0 !important;
    margin-right: to-rem(25px) !important;
  }
}

blockquote {
  @apply tw-italic tw-text-black;

  border-left: 3px solid #000;
  font-size: to-rem(17px);

  padding: 0;
  padding-left: to-rem(25px);
}

.table {
  th,
  td {
    border-top: 1px solid #c4c4c4;
    padding: to-rem(10px);

    p {
      font-size: to-rem(14px) !important;
    }
  }

  thead th {
    border-top: none;
    border-bottom: 1px solid #000;
  }
}

.field-list {
  dt,
  dd {
    margin-top: to-rem(8px) !important;

    p {
      @apply tw-leading-normal;

      &:last-child {
        margin: 0;
      }
    }
  }

  dt {
    @apply tw-text-right;

    font-weight: 600 !important;
  }
}

.option-list {
  kbd {
    @apply tw-font-semibold tw-text-sm tw-rounded-none;

    background-color: #686868;
  }

  dd {
    margin-top: to-rem(15px);
    margin-left: to-rem(25px);
    margin-bottom: to-rem(15px);

    p {
      @apply tw-leading-normal;

      font-size: to-rem(9px);
      margin-bottom: to-rem(15px);

      @screen screen-495 {
        font-size: to-rem(14px);
      }
    }
  }
}

main {
  ol,
  ul {
    li {
      margin-top: to-rem(12px);

      p {
        @apply tw-m-0 tw-ml-1;
      }
    }
  }

  ul {
    @apply tw-list-disc;

    padding-left: to-rem(20px);
  }

  ol {
    @apply tw-pl-5;
  }
}

dl {
  dt {
    @apply tw-leading-normal tw-text-black;

    font-size: to-rem(9px) !important;
    font-weight: 600 !important;

    @screen screen-495 {
      font-size: to-rem(14px) !important;
    }
  }

  dd {
    margin-top: to-rem(15px);
    margin-left: to-rem(25px);

    p {
      font-size: to-rem(9px) !important;
      margin-bottom: to-rem(15px);

      @screen screen-495 {
        font-size: to-rem(14px) !important;
      }

      &:last-child {
        margin-bottom: to-rem(25px);
      }
    }
  }
}

.contents {
  border: none !important;
  border-left: 1px solid black !important;
  background: none !important;
  padding: 0 !important;

  ul {
    @apply tw-p-0;

    li {
      @apply tw-list-none tw-m-0 tw-my-3;
    }

    p {
      @apply tw-m-0;
    }
  }

  a {
    @apply tw-no-underline tw-font-normal;

    font-size: to-rem(14px) !important;
  }

  @for $i from 1 through 5 {
    .toc-l#{$i} p {
      @apply tw-text-sm tw-leading-6 tw-no-underline;

      border-left: 3px solid transparent;
      color: #000 !important;
      padding-left: ($i - 1) * 16px + 25px !important;

      &:hover {
        border-left: 3px solid theme('colors.napari-primary');
      }
    }
  }
}

.admonition {
  --color: theme('colors.napari-primary');

  border: var(--color) solid 1px !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;

  & > p:not(.admonition-title) {
    @apply tw-font-medium;

    margin: 0 !important;
    padding: to-rem(25px) !important;

    font-size: to-rem(9px);

    @screen screen-495 {
      font-size: to-rem(14px);
    }
  }

  &-title {
    @apply tw-uppercase;

    background: var(--color) !important;
    font-size: to-rem(11px) !important;
    padding: to-rem(10px) to-rem(25px);

    @screen screen-495 {
      font-size: to-rem(17px) !important;
    }

    &::before {
      @apply tw-hidden;
    }
  }

  &.danger {
    --color: #ff8080;
  }

  &.warning {
    --color: #ffa680;
  }

  &.caution {
    --color: #ffc580;
  }

  &.error {
    --color: #fade7d;
  }

  &.important {
    --color: #f1f379;
  }

  &.attention {
    --color: #d8f97d;
  }

  &.note {
    --color: #80ffe0;
  }

  &.hint {
    --color: #8094ff;
  }

  &.tip {
    --color: #cf80ff;
  }
}

div.versionadded,
div.versionchanged,
div.deprecated {
  @apply tw-rounded-none tw-shadow-none;

  border: none !important;
  padding: to-rem(25px) !important;

  p {
    @apply tw-font-medium;

    padding: 0;
    margin: 0 !important;
    font-size: to-rem(9px);

    @screen screen-495 {
      font-size: to-rem(14px);
    }
  }
}

.versionadded {
  background: #80ffe0 !important;
}

.versionchanged {
  background: #f1f379 !important;
}

.deprecated {
  background: #ffc580 !important;
}

.versionmodified {
  @apply tw-flex tw-items-center tw-space-x-2 tw-not-italic;

  &::before {
    @apply tw-hidden;
  }

  font-size: to-rem(11px) !important;

  @screen screen-495 {
    font-size: to-rem(17px) !important;
  }
}

div.topic {
  @apply tw-border-none tw-bg-napari-hover-gray;

  padding: to-rem(25px) to-rem(25px) 0;

  p {
    @apply tw-font-medium;
    font-size: to-rem(14px) !important;

    &.topic-title {
      font-weight: 700 !important;
      font-size: to-rem(13px) !important;
      line-height: 125%;

      @screen screen-495 {
        font-size: to-rem(20px) !important;
        line-height: 135%;
      }
    }
  }
}

aside.sidebar {
  @apply tw-border-none tw-mb-0;

  border-left: 3px solid #000;
  font-size: to-rem(14px) !important;
}

h2 code span.pre {
  font-size: to-rem(22px);
}

span.pre {
  @apply tw-font-bold tw-text-black;
}

.footnote {
  @apply tw-grid tw-items-start;

  grid-template-columns: max-content 1fr;
  gap: to-rem(25px) to-rem(17px);

  dd {
    @apply tw-m-0;

    &:after {
      @apply tw-hidden;
    }
  }

  dt {
    @apply tw-justify-self-end;
  }

  p {
    @apply tw-text-sm tw-leading-normal;

    margin-bottom: to-rem(17px) !important;

    &:last-child {
      margin-bottom: 0 !important;
    }
  }
}

#search-documentation {
  @apply tw-mb-3;

  & ~ form {
    @apply tw-flex;

    border-bottom: 1px solid #000;

    input[type='text'] {
      @apply tw-border-none tw-flex-grow;
    }
  }
}

.search-button {
  @apply tw-border-none tw-flex tw-items-center tw-justify-center tw-pr-0;

  background: none;
}

.search-info {
  @apply tw-flex tw-mt-10 tw-items-end tw-space-x-2;

  h2 {
    @apply tw-m-0;
  }

  .search-summary {
    @apply tw-m-0 tw-mb-1;
  }
}

ul.search {
  @apply tw-list-none tw-m-0;

  li {
    @apply tw-bg-none tw-p-0 tw-grid tw-gap-x-3;

    grid-template-columns: min-content 1fr;

    a,
    p {
      font-size: to-rem(17px);
      line-height: to-rem(25.5px);
    }

    a {
      @apply tw-font-semibold tw-no-underline;
    }

    p {
      @apply tw-col-start-2;

      margin: 0 !important;
    }
  }
}

#search-progress {
  padding: 0 !important;
}

span.highlighted {
  @apply tw-bg-napari-light tw-p-px;
}

.highlight {
  pre,
  span {
    font-size: to-rem(11px) !important;
    line-height: 19px;

    @screen screen-495 {
      font-size: to-rem(16px) !important;
      line-height: 28px;
    }
  }

  pre {
    @apply tw-shadow-none tw-border-none tw-rounded-none tw-p-0;

    margin-right: to-rem(25px);
    padding: to-rem(25px);
  }

  &.line-numbers pre {
    padding: to-rem(25px) 0;
  }

  .linenos {
    @apply tw-text-napari-gray;

    margin-left: to-rem(13px);
    margin-right: to-rem(25px);
    font-size: to-rem(9px) !important;

    @screen screen-495 {
      font-size: to-rem(13px) !important;
    }
  }

  .hll {
    @apply tw-bg-napari-light;

    min-height: to-rem(28px);
  }
}

.literal-block {
  @apply tw-shadow-none tw-border-none tw-rounded-none tw-p-0;

  font-size: to-rem(11px) !important;
  line-height: 19px;
  padding: to-rem(25px);

  @screen screen-495 {
    font-size: to-rem(16px) !important;
    line-height: 28px;
  }
}

figure {
  @apply tw-p-0;

  text-align: left !important;

  &.align-center {
    text-align: center !important;
  }

  img {
    @apply tw-w-full tw-max-w-max;
  }

  figcaption {
    @apply tw-p-0;

    padding-top: to-rem(15px);

    .caption-text {
      @apply tw-italic;
    }

    .caption-text,
    .legend table,
    .legend p {
      font-size: to-rem(9px);

      @screen screen-495 {
        font-size: to-rem(14px);
      }
    }
  }
}
