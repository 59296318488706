// Rules that are unique to MyST markdown parsing / outputs

th,
td {
  &.text-align\:left {
    text-align: left;
  }

  &.text-align\:right {
    text-align: right;
  }

  &.text-align\:center {
    text-align: center;
  }
}
