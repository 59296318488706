@import '@/scss/utils';

.napari-footer {
  @apply tw-flex tw-flex-row tw-flex-wrap;
  @apply tw-items-center tw-justify-between tw-w-full;
  @apply tw-pt-6 tw-pb-2 tw-px-6 screen-495:tw-px-12 tw-bg-black;

  .footer-item {
    @apply tw-flex tw-flex-wrap tw-items-center;

    &--with-napari-copyright {
      @apply tw-w-full tw-justify-end;

      @media (min-width: 780px) {
        @apply tw-w-max tw-justify-start;
      }
    }
  }

  p {
    color: #fff !important;
  }

  a {
    @apply tw-text-white hover:tw-text-napari-light;
    @apply tw-whitespace-nowrap tw-no-underline;
    @apply tw-mr-4 last:tw-mr-0 tw-mb-4;
    @apply tw-flex tw-flex-row tw-items-center;

    & > svg {
      @apply tw-inline-block tw-mr-1;

      height: 1em;
      width: 1em;
    }

    & > span {
      @apply tw-font-semibold;

      font-size: to-rem(9px);

      @screen screen-495 {
        @apply tw-text-sm;
      }
    }
  }

  .napari-copyright {
    @apply tw-flex tw-flex-col tw-text-white;

    &,
    .copyright {
      @apply tw-font-semibold;

      font-size: to-rem(9px);

      @screen screen-495 {
        @apply tw-text-sm;
      }
    }

    .sphinx-link,
    .sphinx-version {
      @apply tw-flex;
    }

    .sphinx-link {
      @apply tw-space-x-1;
    }

    .sphinx-version {
      @apply tw-justify-between;
    }
  }

  .footer-icon {
    &__hover-blue {
      .footer-icon__light-blue {
        @apply tw-hidden;
      }

      &:hover {
        .footer-icon__regular {
          @apply tw-hidden;
        }

        .footer-icon__light-blue {
          @apply tw-block;
        }
      }
    }
  }
}
