@import '@/scss/utils';

.bd-sidebar {
  @apply tw-pt-8;

  li {
    @apply tw-flex tw-flex-col;

    & > a.reference {
      @apply tw-pr-0;
    }
  }

  a.external::after {
    @apply tw-hidden;
  }

  label {
    @apply tw-left-0;

    right: unset;

    &:hover {
      background: none;
    }
  }

  .caption-text {
    font-size: to-rem(11px) !important;
  }

  a.reference,
  .caption-text {
    @apply tw-no-underline tw-text-sm;

    color: #000 !important;
  }

  .current.active > a.reference {
    @apply tw-font-semibold;
  }

  .toctree-checkbox {
    & ~ label > img {
      @apply tw-select-none tw-transition-transform;
    }

    &:checked ~ label > img {
      transform: rotate(90deg);
    }
  }

  .nav ul {
    @apply tw-p-0;
  }

  .toctree-l1 a {
    padding-left: 32px !important;
  }

  .toctree-l2 {
    @apply tw-ml-4;

    border-left: 1px solid #000;
  }

  @for $i from 2 through 5 {
    .toctree-l#{$i} {
      label {
        left: #{2px * $i};
      }

      a {
        @apply tw-text-sm tw-leading-6 tw-no-underline;

        border-left: 2px solid transparent;
        color: #000 !important;
        padding-left: #{30px * ($i - 1)} !important;

        &:hover,
        &.current:hover {
          border-left: 2px solid theme('colors.napari-primary');
        }

        &.current {
          border-left: 2px solid #000;
        }
      }
    }
  }
}
