form.search {
  @apply tw-flex tw-items-center tw-justify-between;

  border-bottom: 1px solid black;

  input {
    @apply tw-border-none tw-flex-grow;

    outline: none;
    outline-offset: 0;

    &:focus {
      @apply tw-outline-napari-primary;
    }
  }
}
