@import '@/scss/utils';

.navbar {
  &,
  &-light {
    @apply tw-shadow-none;

    background-color: theme('colors.napari-primary') !important;
  }

  .navbar-toggler {
    border: none !important;
  }

  .navbar-brand {
    @apply tw-font-bold tw-no-underline;

    p.title {
      font-size: to-rem(16px);
      line-height: 125%;

      @screen screen-495 {
        font-size: to-rem(24px);
        line-height: 150%;
      }
    }
  }

  .nav-item {
    a {
      @apply tw-no-underline;

      color: #000 !important;
      font-size: to-rem(17px);
      padding: to-rem(16px) 0 !important;
      font-weight: 500 !important;

      @media (min-width: 960px) {
        @apply tw-text-center;
      }
    }

    &.active a {
      font-weight: 700 !important;
    }

    a > i {
      @apply tw-ml-2;

      font-size: to-rem(14px) !important;
    }

    @media (min-width: 960px) {
      a {
        padding: 0 to-rem(17px) to-rem(20px) !important;
        margin-top: to-rem(20px) !important;
        border-bottom: to-rem(3px) solid transparent;
      }

      &.active a,
      &:hover a {
        border-bottom: to-rem(3px) solid #000;
      }
    }
  }
}

#version_switcher {
  button {
    @apply tw-border-none tw-text-black tw-font-medium tw-text-sm tw-p-0 tw-pt-3;
    @apply tw-flex tw-items-center;

    background: none !important;

    &::after {
      display: none;
    }

    @media (min-width: 960px) {
      @apply tw-pt-0;
    }
  }

  .version-switcher-chevron {
    margin-left: to-rem(10px);
  }

  &.show .version-switcher-chevron {
    transform: rotate(180deg);
  }

  a {
    @apply tw-no-underline tw-text-black;

    font-size: to-rem(9px);

    @screen screen-495 {
      font-size: to-rem(14px);
    }
  }
}

#version_switcher_menu {
  @apply tw-min-w-24;

  .list-group-item {
    @apply tw-px-2 tw-border-l tw-border-r tw-border-b tw-border-napari-dark-gray;

    &:first-child {
      @apply tw-border-t;
    }
  }
}

.navbar-collapse {
  padding: 0 to-rem(25px) to-rem(25px);

  @screen screen-495 {
    padding: 0 to-rem(50px) to-rem(50px);
  }

  @media (min-width: 960px) {
    padding: 0 !important;
  }
}
